import { Link, NavLink, useLocation, useNavigate, useSearchParams } from 'react-router'
import { i18n } from './i18n'
import Icon from './components/Icon'
import SearchBox from './components/SearchBox'

export const NavBar = () => {
  const { visitor, retpath } = window.state
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div id="header">
      <div id="header_wrapper">
        {
          visitor.uid > 0 ? (
            <div id="user_wrapper">
              <a href={`/${visitor.uname}/`}>
                <img src={visitor.avatar} alt="" />{visitor.uname}
                {
                  visitor.premium &&
                  <span style={{ color: 'green'}}><Icon name="ei-star" size="s" /></span>
                }
                {!visitor.verified &&
                  <span style={{ color: 'red'}}><Icon name="ei-exclamation" size="s" /></span>
                }
              </a>
            </div>
          ) : (
            <div id="logo"><a href="/">Juick</a></div>
          )
        }
        <div id="search" className="desktop">
          <SearchBox defaultValue={ params.get('search') || '' } onSearch={(searchString) => {
            const params = new URLSearchParams()
            params.set('show', 'search')
            params.set('search', searchString)
            navigate({
              pathname: '/',
              search: params.toString()
            }, {})
          }} />
        </div>
        <nav id="global">
          <Link to="/" reloadDocument={true}>
            <Icon name="ei-bell" size="s" />
            <span className="icon-title desktop">{ i18n('link.discuss') }</span>{ visitor.unreadCount > 0 && <span className="badge">{ visitor.unreadCount }</span> }
          </Link>
          <Link to="/?show=all" rel="nofollow" reloadDocument={true}>
            <Icon name="ei-search" size="s" />
            <span className="icon-title desktop">{ i18n('link.allMessages') }</span>
          </Link>
          {
            visitor.uid > 0 ? (
              <NavLink id="post" to="/post" state={{ 
                isModal: true, 
                background: {
                  pathname: location.pathname,
                  search: location.search,
                  state: location.state
                }
              }}>
                <Icon name="ei-pencil" size="s" />
                <span className="icon-title desktop">{ i18n('link.postMessage') }</span>
              </NavLink>
            ) : (
              <NavLink className="a-login" to={`/login?retpath=${ retpath || '/'}`} rel="nofollow">
                <Icon name="ei-user" size="s" />
                <span className="icon-title desktop">{ i18n('link.Login') }</span>
              </NavLink>
            )
          }
        </nav>
      </div>
    </div>
  )
}
