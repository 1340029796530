import { createBrowserRouter, RouterProvider } from 'react-router'
import { AppLayout } from './layout'
import { createRoot } from 'react-dom/client'
import { Post } from './post'
import { Settings } from './settings'
import { Login } from './login'
import { Thread } from './thread'
import Contacts from './chats'
import Chat from './chat'
import { Main } from './main'
import { ModalRoute } from './components/ModalRoute'

let router = createBrowserRouter([
  {
    Component: AppLayout,
    children: [
      // Main route with its modal children
      {
        path: '/',
        Component: Main,
        children: [
          {
            path: 'post',
            element: <ModalRoute component={Post} shouldRenderAsModal={true} />,
          },
          {
            path: ':user/:mid',
            element: <ModalRoute component={Thread} shouldRenderAsModal={true} />,
          }
        ]
      },
      // Full page routes
      {
        path: '/post',
        element: <ModalRoute component={Post} shouldRenderAsModal={false} />
      },
      {
        path: '/:user/:mid',
        element: <ModalRoute component={Thread} shouldRenderAsModal={false} />
      },
      {
        path: '/login',
        Component: Login
      },
      {
        path: '/settings',
        Component: Settings
      },
      {
        path: '/pm',
        Component: Contacts
      },
      {
        path: '/chat',
        Component: Chat
      }
    ]
  }
])

const App = () => {
  return (
    <RouterProvider router={router} />
  )
}

let root = document.getElementById('app')
createRoot(root).render(<App />)
